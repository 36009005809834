import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AdminService } from '../services/admin.service';
import { Router } from '@angular/router';
import { General, wallImages } from '../models/model'
import {DomSanitizer,SafeResourceUrl,} from '@angular/platform-browser';

@Component({
  selector: 'app-schoolsjourney',
  templateUrl: './schoolsjourney.component.html',
  styleUrls: ['./schoolsjourney.component.css']
})
export class SchoolsjourneyComponent implements OnInit {
  // alljourney:any;
  allWallPodcast:any;
  allWallchronicles:any;
  allWallvideolog:any;
  allWalltravelbook:any;
  allWallblueblogger:any;
  allWalltestimonial:any;
  allWallJourneyMoments:any;
  allWallJourneyChamps:any;

  urlSafe: SafeResourceUrl;
 zeropodcastYurl={};
 zeropodcastCreator="";
 zeropodcastHeading="";
 zeropodcastThumbnail="";

 zeroVideoYurl:{};
 zeroVideoCreator="";
 zerovideoHeading="";
 zeroVideoThumbnail="";

 zeroBlogCreator="";
 zeroBlogHeading="";
 zeroBlogImage="";
 zeroBlogdesc="";

 zeroTestimonialCreator="";
 zeroTestimonialHeading="";
 zeroTestimonialImage="";
 zeroTestimonialreview="";
 zeroTestimonialRating="";
 zeroTestimonialPublishdate="";
// arr=[];
zeroWallJourneyMoments={};
  constructor(private _api:AdminService, private router: Router,public sanitizer: DomSanitizer) { }
  allWallpodcast=[];
  allWallvideo=[];
  
  zeroChroniclPdf="";
  ChroniclPdf="";
  zeroChronicleDesc="";
  zeroChronicleHeading="";

  zeroTravelbookPdf="";
  TravelbookPdf="";
  zeroTravelbookHeading="";
  ngOnInit() {
    this.allWallpodcast=[];
    this.allWallvideo=[];
    
    this.getJourneyDataByWallandJouneyId();
  }
  getJourneyDataByWallandJouneyId() {
    console.log("Inside Getjourneybyid");
    // let id = this.data._id
    // let id = "5e4cc27acb86a218d3292bcd"
    let data={wall_id : "5e4cc27acb86a218d3292bcd",journeyName:window.localStorage.getItem('journeyName')}

    this._api.getJourneyDataByWallandJouneyId(data).subscribe((res: any) => {
      if (!res.error) {
        console.log(res)
      this.allWallPodcast = res.allWallPodcast
      if(this.allWallPodcast.length>0) {
     let zerodata =  this.allWallPodcast.shift();
     let zeroStr= zerodata.PodcastYTurl.split('/')
     let Zerourl = zeroStr.pop();
     this.zeropodcastThumbnail="https://img.youtube.com/vi/"+Zerourl+"/0.jpg"
     this.zeropodcastYurl = this.sanitizer.bypassSecurityTrustResourceUrl(zerodata.PodcastYTurl);      
     this.zeropodcastCreator=zerodata.PodcastCreatorName;
     this.zeropodcastHeading= zerodata.PodcastHeadline
      }
     let podcastObj={};
        this.allWallPodcast.forEach((element,i) => {
          let str= element.PodcastYTurl.split('/')
          // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          this.allWallPodcast[i].PodcastYTurl= this.sanitizer.bypassSecurityTrustResourceUrl(element.PodcastYTurl);
          let url = str.pop();
          podcastObj={"thumbnail":"https://img.youtube.com/vi/"+url+"/0.jpg","headline":element.PodcastHeadline}
          this.allWallpodcast.push(podcastObj)
  
        });
        this.allWallchronicles= res.allWallchronicles;
        if(this.allWallchronicles.length>0){
          let zerodata =  this.allWallchronicles.shift();
          this.zeroChroniclPdf=zerodata.chroniclespdfupload;
          this.ChroniclPdf=zerodata.chroniclespdfupload;
          // this.zeroChronicleDesc=zerodata.chroniclesDescriptionpdf;
          this.zeroChronicleHeading=zerodata.chroniclesHeadlinepdf;
        }
        this.allWallvideolog= res.allWallvideolog;
        this.allWallvideolog = res.allWallvideolog
        if(this.allWallvideolog.length>0) {
     let zeroVideodata =  this.allWallvideolog.shift();
     let zerovideoStr= zeroVideodata.VideoLogYTurl.split('/')
     let ZeroVideourl = zerovideoStr.pop();
     this.zeroVideoThumbnail="https://img.youtube.com/vi/"+ZeroVideourl+"/0.jpg"
     this.zeroVideoYurl = this.sanitizer.bypassSecurityTrustResourceUrl(zeroVideodata.VideoLogYTurl);      
     this.zeroVideoCreator=zeroVideodata.VideoLogCreatorName;
     this.zerovideoHeading= zeroVideodata.VideoLogHeadline;
        }
        let videoObj={};
        this.allWallvideolog.forEach((element,i) => {
          let str= element.VideoLogYTurl.split('/')
          // this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
          this.allWallvideolog[i].VideoLogYTurl= this.sanitizer.bypassSecurityTrustResourceUrl(element.VideoLogYTurl);
          let url = str.pop();
          videoObj={"thumbnail":"https://img.youtube.com/vi/"+url+"/0.jpg","headline":element.VideoLogHeadline}
          this.allWallvideo.push(videoObj)

        });
        this.allWalltravelbook= res.allWalltravelbook;
        if(this.allWalltravelbook.length>0) {
          let zeroTravelBookdata =  this.allWalltravelbook.shift();
          this.zeroTravelbookPdf=zeroTravelBookdata.travelbookpdfupload;
          this.TravelbookPdf=zeroTravelBookdata.travelbookpdfupload;
          this.zeroTravelbookHeading=zeroTravelBookdata.travelbookHeadlinepdf;
        }
        this.allWallblueblogger= res.allWallblueblogger;
        if(this.allWallblueblogger.length>0) {
          let zeroBlogdata =  this.allWallblueblogger.shift();
          this.zeroBlogCreator=zeroBlogdata.bluebloggercreatorName;
          this.zeroBlogHeading=zeroBlogdata.bluebloggerHeadline;;
          this.zeroBlogImage=zeroBlogdata.bluebloggerimageblog;;
          this.zeroBlogdesc=zeroBlogdata.bluebloggerbloginfo;;
        }
        this.allWalltestimonial= res.allWalltestimonial;
        if(this.allWalltestimonial.length>0) {
          let zeroTestimonialdata =  this.allWalltestimonial.shift();
          this.zeroTestimonialCreator=zeroTestimonialdata.testimonialcreatorName;
          this.zeroTestimonialHeading=zeroTestimonialdata.testimonialHeadline;
          this.zeroTestimonialImage=zeroTestimonialdata.testimonialimageblog;
          this.zeroTestimonialreview=zeroTestimonialdata.testimonialreview;
          this.zeroTestimonialRating=zeroTestimonialdata.testimonialreview;
          this.zeroTestimonialPublishdate=zeroTestimonialdata.publishDate;
        }
        this.allWallJourneyMoments= res.allWallJourneyMoments;
        if(this.allWallJourneyMoments.length>0) {
          this.zeroWallJourneyMoments =  this.allWallJourneyMoments.shift();
                }
        this.allWallJourneyChamps= res.allWallJourneyChamps;
        console.log("here here");

      } else {
        console.log(" no Data")
        Swal.fire('', res.message, 'error')
      }
    })
  }
  setpdf(data) {
    this.ChroniclPdf=data.chroniclespdfupload;
  }
  setZeropdf(data) {
    this.ChroniclPdf=data;
  }
  setTravelpdf(data) {
    this.TravelbookPdf=data.travelbookpdfupload;
  }
  setTravelZeropdf(data) {
    this.TravelbookPdf=data;
  }
}
