import { Component, HostListener, OnInit } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import Swal from 'sweetalert2';
import { AdminService } from '../services/admin.service';
import { Router } from '@angular/router';
import { PrincipalDesk, wallImages } from '../models/model'

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  // animations: [
  //   trigger('fade', [ 
  //     state('initial', style({
  //       display: 'none',
  //       top: -700,
  //       opacity: 1,
  //     })),
  //     state('final', style({
  //       display: 'block',
  //       top: 100,
  //       opacity: 1,
  //     })),
      // transition('initial=>final', animate('300ms ease-in')),
      // transition('final=>initial', animate('300ms ease-in'))
    // ])
  // ]
})
export class HomeComponent implements OnInit {
  PrincipalDesk= new PrincipalDesk()
  constructor(private _api:AdminService, private router: Router) { }

  isShown: boolean = false ; // hidden by default
  currentState = 'initial';
  currentSection = 'section1';
  scrollPosition = 0;
  isVisible = true; 
  toggle : boolean = false;
  wallImage = new wallImages();
  alljourney:any;
  
  // openMediaPopup($event: any){    
  //   this.currentState = this.currentState === 'initial' ? 'final' : 'initial';
   
  // }
  
  @HostListener('window:scroll', ['$event'])
  
   scrollTo(sectionsItem: string) {
    document.querySelector('#' + sectionsItem).scrollIntoView({ behavior: 'smooth' });
   }

  ngOnInit() {
    // alert("Window load Event - working");
    let path= window.location.pathname;
    this.getwallImages()
    this.getallJourneypodcast()
    this.getwebHomeDataById();
    var bookCoverPath = '';
    $('.btn-zoom').on('click', function(){
      bookCoverPath = $(this).closest('.video-player-holder').find(".carousel-item.active").children("img").attr("src");
      console.log($(this).closest('.video-player-holder').find(".carousel-item.active").html());
      $("#bookCoverView").find(".modal-body").children("img").attr("src", bookCoverPath);
    })
   
    $("#openFullMediaPopup").on('click', function(){
        $("#fullMediaPopup").fadeIn(500);
    });
    $("#closeFullMediaPopup").on('click', function(){
        $("#fullMediaPopup").fadeOut(500);
    });
    $(".video-controls li .btn-link").on('click', function(){
      $(this).toggleClass("active");
    });



$(window).on('resize scroll', function() {



  $(".school-journey-single").each(function () {
    var thisTopPosition = $(this).offset().top - $(window).scrollTop();
    var lastSectionPos = $(".school-journey-single").last().offset().top - $(window).scrollTop();
    var firstSectionPos = $(".school-journey-single").first().offset().top - $(window).scrollTop();
    var thisBottomPosition = $(this).innerHeight();
    var winHeight = $(window).height();
    var sectionId = $(this).attr('id');
    // var sectionTitleHeight = $(this).find('.section-title')
    
    
      if((thisTopPosition <= 200) && (thisTopPosition >= -thisBottomPosition)){
          $('.section-title').addClass('item-hide');
          $(this).find('.section-title').removeClass('item-hide');
          $(this).find('.section-title').addClass('item-show');
          $(this).find('.section-title').addClass('item-fixed');
          $('.sliding-menu ').removeClass('item-hide');
          // $('.sliding-menu ').addClass('item-show');
          $('.scrollspy-item').removeClass('active');
          $("#"+sectionId+'-nav').addClass('active');

      } else {
        $(this).find('.section-title').addClass('item-hide');  
        $(this).find('.section-title').removeClass('item-show');  
      }
      
      if( (firstSectionPos >= 15)&&(firstSectionPos <= winHeight+200)){
         $(this).find('.section-title').addClass('position-relative');
         $(this).find('.section-title').addClass('item-show');
         $(this).find('.section-title').removeClass('item-fixed');
         $(this).find('.section-title').removeClass('item-hide'); 

        //  $('.sliding-menu').addClass('item-show');
         $('.sliding-menu').removeClass('item-fixed');
         $('.sliding-menu').removeClass('item-hide'); 
         $('.sliding-menu').addClass('item-absolute');
      }
      if(firstSectionPos <= 14){
        var sectionTitlePos = $(".section-journey").find('.section-title').offset().top;  
        var sectionTitleHeight = $(".section-journey").find('.section-title').height;  
        $(this).find('.section-title').removeClass('position-relative');
        $(this).find('.section-title').addClass('item-fixed');
       
        $('.sliding-menu').removeClass('position-relative item-absolute');
        $('.sliding-menu').removeClass('item-fixed');
      }
      if( firstSectionPos >= 100){
        $('.action-bar-stickey').removeClass('pos-fixed');
     }
     if(firstSectionPos <= 100){
      $('.action-bar-stickey').addClass('pos-fixed');
     }
     if( (lastSectionPos <= 0)&&(firstSectionPos <= winHeight)){
      $('.action-bar-stickey').removeClass('pos-fixed');
     }
     

      if( (lastSectionPos >= 0)&&(firstSectionPos <= winHeight)){
        $('.sliding-menu').removeClass('item-fixed sliding-bottom-menu');
        $('.sliding-menu').removeClass('item-absolute-bottom');
     }
     if(lastSectionPos <= -10){
       var sectionTitlePos = $(".section-journey").find('.section-title').offset().top;  
       var sectionTitleHeight = $(".section-journey").find('.section-title').height;  
       $(this).find('.section-title').addClass('position-relative');
       $(this).find('.section-title').removeClass('item-fixed');
       $('.sliding-menu').addClass('item-absolute-bottom');
     }
  });
  
});



    function initCarouselIndicators() {
      $(".carousel-indicators[data-target]").each(function (i: any, indicators: { dataset: { target: any; }; }) {
          var targetId = indicators.dataset.target;
          if (targetId != "") {
              var $carousel = $(targetId);
              $carousel.bind('slide.bs.carousel', function (e: { relatedTarget: any; }) {
                  var $targetSlide = $(e.relatedTarget);
                  var index = $targetSlide.index();
                  $('.carousel-indicators[data-target="' + targetId + '"] li').removeClass('active')
                  $('.carousel-indicators[data-target="' + targetId + '"] li:nth-child(' + (index + 1) + ')').addClass('active');
              });
          }
      });
  }
  initCarouselIndicators();
  

  
  }
  getwallImages() {
    let wall_id = "5e4cc27acb86a218d3292bcd"

    this._api.getWallImage({ wall_id }).subscribe((res: any) => {
      if (!res.error) {

        // Swal.fire('', res.message, 'success')
        this.wallImage = res.WallImage
        window.localStorage.setItem('footerArt',res.wallImage.footerArt)
        // let footerArt = window.localStorage.getItem('footerArt')
        // console.log(footerArt)
      } else {
        console.log(" no Data")
        Swal.fire('', res.message, 'error')
      }
    })

  }
  getallJourneypodcast() {
    console.log("Inside Getjourneybyid");
    // let id = this.data._id
    let id = "5e4cc27acb86a218d3292bcd"
    this._api.getAllJourneyById({id}).subscribe((res: any) => {
      if (!res.error) {
        console.log(res)
        this.alljourney = res.allWallJourneyById
        console.log("here here");

      } else {
        console.log(" no Data")
        Swal.fire('', res.message, 'error')
      }
    })
  }
  getwebHomeDataById() {
    console.log("Inside Getjourneybyid");
    // let id = this.data._id
    let data={wall_id : "5e4cc27acb86a218d3292bcd",journeyName:"demo1"}
    
    this._api.getwebHomeDataById(data).subscribe((res: any) => {
      if (!res.error) {
        console.log(res)
        this.PrincipalDesk = res.WallPrincipalDesk
        console.log("here here");

      } else {
        console.log(" no Data")
        Swal.fire('', res.message, 'error')
      }
    })
  }
  getJourneyData(event) {
console.log(event);
window.localStorage.setItem("journeyName",event.target.name)
// this.router.navigate(['/'])
  }
}
