import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { LoadingScreenInterceptor } from './loading.interceptor';
import { PdfViewerModule } from 'ng2-pdf-viewer'; // <- import PdfViewerModule
import { NgxDocViewerModule } from 'ngx-doc-viewer'
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { PopupstudentgallryComponent } from './popupstudentgallry/popupstudentgallry.component';
import { HeaderComponent } from './header/header.component';
import { SchoolsjourneyComponent } from './schoolsjourney/schoolsjourney.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PopupstudentgallryComponent,
    HeaderComponent,
    ScrollSpyDirective,
    SchoolsjourneyComponent,

  ],
  imports: [
    BrowserModule,
    NgxDocViewerModule,
    PdfViewerModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
  ],
  providers: [
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: LoadingScreenInterceptor,
    //   multi: true
    // }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
