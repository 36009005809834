import { Component } from '@angular/core';
import {trigger, state, style, animate, transition} from '@angular/animations';
import Swal from 'sweetalert2';
import { AdminService } from './services/admin.service';
import { Router } from '@angular/router';
import {  wallImages } from './models/model'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  
})
export class AppComponent {
  title = 'schoolwall';
  fields=new wallImages();
  constructor(private _api:AdminService, private router: Router) { }
  ngOnInit() {
    this.getwallImages();
  }
  getwallImages() {
    let wall_id = "5e4cc27acb86a218d3292bcd"

    this._api.getWallImage({ wall_id }).subscribe((res: any) => {
      if (!res.error) {

        // Swal.fire('', res.message, 'success')
        this.fields = res.WallImage
        window.localStorage.setItem('footerArt',res.wallImage.footerArt)
        // let footerArt = window.localStorage.getItem('footerArt')
        // console.log(footerArt)
      } else {
        console.log(" no Data")
        Swal.fire('', res.message, 'error')
      }
    })

  }
}
