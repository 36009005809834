import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  token = window.localStorage.getItem('token')

  httpOptions2 = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    })
  };
  httpOptions4 = {
    headers: new HttpHeaders({

    })
  };



  API_URL = environment.Api_url;
  constructor(private httpClient: HttpClient) { }
  getAllJourney(data) {
    return this.httpClient.post(this.API_URL + '/admin/getAllJourney', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  addEvent(data) {
    // let token2 = window.localStorage.getItem('token')
    return this.httpClient.post(this.API_URL + '/admin/addEvents', data, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        // 'Authorization': token2
      })
    })
  }
  getWallImage(data) {
    return this.httpClient.post(this.API_URL + '/admin/getWallImage', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })
  }
  getAllJourneyById(data) {
    return this.httpClient.post(this.API_URL + '/admin/getAllJourneyById', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

  }
  
  getwebHomeDataById(data) {
    return this.httpClient.post(this.API_URL + '/admin/getWhiteWallHomeDataByWallandJouneyId', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

  }
  getJourneyDataByWallandJouneyId(data) {
    return this.httpClient.post(this.API_URL + '/admin/getWhiteWallDataByWallandJouneyId', data, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) })

}
}