

export class Login {
    email: string;
    password: string;
    browserId: string;

}
export class General {
    _id: string;
    wall_id: string;
    journeyName: string;
    journeyDate: Date;
    journeyCountry: string;
    createdAt: Date;
    updatedAt: Date;
}
export class Podcast {
    _id: string;
    wall_id: string;
    PodcastjourneyName: string;
    PodcastHeadline: string;
    PodcastCreatorName: string;
    PodcastYTurl: string;
    createdAt: string;
    updatedAt: Date;
}
export class VideoLogs {
    _id: string;
    wall_id: string;
    VideoLogjourneyName: string;
    VideoLogHeadline: string;
    VideoLogCreatorName: string;
    VideoLogYTurl: string;
    createdAt: Date;
    updatedAt: Date;
}
export class StudentTravelChronicles {
    _id: string;
    wall_id: string;
    chroniclesjourneyName: string;
    chroniclesHeadlinepdf: string;
    chroniclespdfupload: string;
    createdAt: Date;
    updatedAt: Date;

}
export class travelbook {
    _id: string;
    wall_id: string;
    travelbookjourneyName: string;
    travelbookHeadlinepdf: string;
    travelbookpdfupload: string;
    createdAt: Date;
    updatedAt: Date;

}
export class blueblogger {
    _id: string;
    wall_id: string;
    bluebloggerjourneyName: string;
    bluebloggerHeadline: string;
    bluebloggercreatorName: string;
    bluebloggerimageblog: string;
    bluebloggerbloginfo: string;
    createdAt: Date;
    updatedAt: Date;

}
export class wallImages {
    _id: string;
    wall_id: string;
    schoolLogo: string;
    moodleLogo: string;
    scrollingArt1: string;
    scrollingArt2: string;
    footerArt: string;
    status: string;
    createdAt: Date;
    updatedAt: Date;

}
export class testimonial {
    _id: string;
    wall_id: string;
    testimonialjourneyName: string;
    testimonialHeadline: string;
    testimonialcreatorName: string;
    testimonialimageblog: string;
    testimonialreview: string;
    publishDate: string;
    starRating: string;
    createdAt: Date;
    updatedAt: Date;

}
export class JourneyMoments {
    _id: string;
    wall_id: string;
    journeyName: string;
    journeyMoments: string;

    images: Array<string>;
    createdAt: Date;
    updatedAt: Date;

}
export class JourneyChamps {
    _id: string;
    wall_id: string;

    journeyName: string;
    studentName: string;
    createdAt: Date;
    updatedAt: Date;

}
export class PrincipalDesk {
    _id: string;
    wall_id: string;

    principalImage: string;
    message: string;
    createdAt: Date;
    updatedAt: Date;

}

export class ForgotPassword {
    email: string;
}

export class wall {
    _id: string;
    school: string;
    city: string;
    state: string;
    country: string;
    websiteURL: string;
    status: Boolean;
    createdAt: Date;
}







export class admins {
    _id: string;
    profile_picture: string;
    firstName: string;
    lastName: string;
    street: string;
    otp: string;
    city: string;
    state: string;
    address: string;
    pin: string;


    GSTIN: string;
    companyLogo: string;
    companyName: string;
    companyDescription: string;
    companyAddress: string;
    website: string;
    services: string;
    serviceArea: string;
    awards: string;
    lat: string;
    lng: string;

    fbid: string;
    twitterid: string;
    instaid: string;
    linkdinid: string;

    ifsc: string;
    bankName: string;
    accountNumber: string;
    createdBy: string;
    prime: string;
    isBlocked: string;
    inviteLink: string;
    deviceType: string;
    socialId: string;
}





